import React, { Fragment } from 'react';
import dynamic from 'next/dynamic';
import { Box } from 'theme-ui';

import { getEnumValueByStringValue } from 'utils/lodash/client';

import { BadgeVariant } from 'components/atoms/Badge/types';
import {
  ButtonMode,
  ButtonModeType,
  ButtonSize,
} from 'components/atoms/Buttons/Button/types';
import {
  SectionBackground,
  SectionSpacing,
} from 'components/styles/Section/enums';
import { HeroWithMediaProps } from 'components/organisms/Heros/HeroWithMedia/types';
import { CTAHorizontalMode } from 'components/organisms/CTA/types';
import LazyHydrate from 'components/LazyHydrate';
import { PageSectionsRendererProps } from './types';
import { getIconByName } from './utils';
import RichTextRenderer from './RichTextRenderer';

const HeroWithMedia = dynamic(
  () => import('components/organisms/Heros/HeroWithMedia'),
);
const FindATMSection = dynamic(
  () => import('components/sections/FindATMSection'),
);
const LogosSection = dynamic(() => import('components/sections/LogosSection'));
const FeaturesSection = dynamic(
  () => import('components/sections/FeaturesSection'),
);
const CTAHorizontal = dynamic(
  () => import('components/organisms/CTA/Horizontal'),
);
const CTAVertical = dynamic(() => import('components/organisms/CTA/Vertical'));
const StepSectionWithContent = dynamic(
  () => import('components/sections/StepSection/WithContent'),
);
const StepSectionWithMedia = dynamic(
  () => import('components/sections/StepSection/WithMedia'),
);
const TabsSection = dynamic(() => import('components/sections/TabsSection'));
const SeparatorSection = dynamic(
  () => import('components/sections/SeparatorSection'),
);
const TextMediaListSection = dynamic(
  () => import('components/sections/TextMediaListSection'),
);
const HeroWithForm = dynamic(
  () => import('components/organisms/Heros/HeroWithForm'),
);
const BuyBitcoinForm = dynamic(
  () => import('components/organisms/Forms/BuyBitcoinForm'),
);
const TransferFormSection = dynamic(
  () => import('components/sections/TransferFormSection'),
);
const VerifyFormSection = dynamic(
  () => import('components/sections/VerifyFormSection'),
);
const CmsSection = dynamic(() => import('components/sections/CMSSection'));
const PersonalDetailsSection = dynamic(
  () => import('components/sections/PersonalDetailsSection'),
);
const NumberInfoListSection = dynamic(
  () => import('components/sections/NumberInfoListSection'),
);
const IframeWrapper = dynamic(
  () => import('components/molecules/IframeWrapper'),
);
const Faq = dynamic(() => import('components/sections/Faq'));
const NewsFeedSection = dynamic(
  () => import('components/sections/NewsFeedSection'),
);
const TwitterFeedSection = dynamic(
  () => import('components/sections/TwitterFeedSection'),
);
const NavigationSection = dynamic(
  () => import('components/sections/NavigationSection'),
);
const ColumnsContentSection = dynamic(
  () => import('components/sections/ColumnsContentSection'),
);
const ProductListSection = dynamic(
  () => import('components/sections/ProductsListSection'),
);
const WalletsList = dynamic(() => import('components/organisms/WalletsList'));
const VideoSection = dynamic(() => import('components/sections/VideoSection'));
const NewsletterSection = dynamic(
  () => import('components/sections/NewsletterSection'),
);
const ArticleTeaserSection = dynamic(
  () => import('components/sections/ArticleTeaserSection'),
);

const PageSectionsRenderer: React.FC<PageSectionsRendererProps> = ({
  sections,
}) => (
  <Fragment>
    {sections.map((section, sectionIndex) => {
      const { key, sectionId } = section;
      const sectionProps =
        section.type !== 'text_with_media' && section.type !== 'iframe'
          ? {
              key,
              as: sectionIndex !== 0 ? 'div' : undefined,
              sectionType: section.type,
              skip: sectionIndex !== 0,
              id: sectionId,
              background: section.background
                ? getEnumValueByStringValue(
                    SectionBackground,
                    section.background,
                  )
                : undefined,
              topSpacing: section.spacing
                ? getEnumValueByStringValue(SectionSpacing, section.spacing)
                : undefined,
              bottomSpacing: section.spacing
                ? getEnumValueByStringValue(SectionSpacing, section.spacing)
                : undefined,
            }
          : {
              key,
              as: sectionIndex !== 0 ? 'div' : undefined,
              skip: sectionIndex !== 0,
              id: sectionId,
              sectionType: section.type,
            };

      const getSectionContent = () => {
        switch (section.type) {
          case 'hero': {
            const {
              links,
              description,
              title,
              videoUrl,
              image,
              mobileImage,
            } = section;

            const getMedia = (): HeroWithMediaProps['media'] => {
              if (image) {
                return {
                  type: 'image',
                  src: image.url,
                  width: image.dimensions.width,
                  height: image.dimensions.height,
                  mobile: mobileImage
                    ? {
                        src: mobileImage.url,
                        width: mobileImage.dimensions.width,
                        height: mobileImage.dimensions.height,
                        alt: mobileImage.alt,
                      }
                    : undefined,
                };
              }

              if (videoUrl) {
                return {
                  type: 'video',
                  src: videoUrl,
                };
              }

              return undefined;
            };

            return (
              <HeroWithMedia
                media={getMedia()}
                {...{
                  title,
                  description,
                  ...sectionProps,
                }}
                links={links.map(
                  ({ href, label, mode, modeType, size }, index) => ({
                    href,
                    content: <Fragment>{label}</Fragment>,
                    key: `${index}${href}`,
                    mode: mode
                      ? getEnumValueByStringValue(ButtonMode, mode)
                      : undefined,
                    modeType: modeType
                      ? getEnumValueByStringValue(ButtonModeType, modeType)
                      : undefined,
                    size: size
                      ? getEnumValueByStringValue(ButtonSize, size)
                      : undefined,
                  }),
                )}
              />
            );
          }
          case 'map_section': {
            const { mapTitle, showOnlySellAtmsByDefault } = section;

            return (
              <FindATMSection
                {...{ ...sectionProps, showOnlySellAtmsByDefault }}
                title={mapTitle}
              />
            );
          }
          case 'logos': {
            const { items, title } = section;

            return (
              <LogosSection
                {...{ title, ...sectionProps }}
                items={items.map(
                  ({ logo: { url, alt, dimensions }, url: href }) => ({
                    key: url,
                    src: url,
                    alt,
                    width: dimensions.width,
                    height: dimensions.height,
                    href,
                  }),
                )}
              />
            );
          }
          case 'features_section': {
            const {
              title,
              description,
              items,
              buttonUrl,
              buttonLabel,
            } = section;

            return (
              <FeaturesSection
                items={items.map((item) => ({
                  key: item.key,
                  icon:
                    item.icon && !item.image
                      ? getIconByName(item.icon)
                      : undefined,
                  title: item.title || '',
                  variant: item.background
                    ? getEnumValueByStringValue(BadgeVariant, item.background)
                    : undefined,
                  description: item.description ? (
                    <RichTextRenderer content={item.description} />
                  ) : undefined,
                  subTitle: item.subTitle ? (
                    <RichTextRenderer content={item.subTitle} />
                  ) : undefined,
                  src: item.image?.url,
                  width: item.image?.dimensions.width,
                  height: item.image?.dimensions.height,
                }))}
                linkButton={
                  buttonUrl
                    ? {
                        href: buttonUrl,
                        content: buttonLabel ? (
                          <Fragment>{buttonLabel}</Fragment>
                        ) : undefined,
                      }
                    : undefined
                }
                {...{ title, description, ...sectionProps }}
              />
            );
          }
          case 'cta_section': {
            const {
              description,
              title,
              buttonLink,
              buttonLabel,
              buttonIcon,
              style,
              mode,
              modeType,
              size,
            } = section;
            const CTAComponent = style?.startsWith('horizontal')
              ? CTAHorizontal
              : CTAVertical;

            return (
              <CTAComponent
                horizontalMode={
                  style === 'horizontal-equal-columns'
                    ? CTAHorizontalMode.Half
                    : undefined
                }
                icon={buttonIcon ? getIconByName(buttonIcon) : undefined}
                linkButton={
                  buttonLink
                    ? {
                        href: buttonLink,
                        content: buttonLabel ? (
                          <Fragment>{buttonLabel}</Fragment>
                        ) : undefined,
                        mode: mode
                          ? getEnumValueByStringValue(ButtonMode, mode)
                          : undefined,
                        modeType: modeType
                          ? getEnumValueByStringValue(ButtonModeType, modeType)
                          : undefined,
                        size: size
                          ? getEnumValueByStringValue(ButtonSize, size)
                          : undefined,
                      }
                    : undefined
                }
                description={
                  description ? (
                    <RichTextRenderer content={description} />
                  ) : undefined
                }
                {...{ title, ...sectionProps }}
              />
            );
          }
          case 'step_section': {
            const { description, title } = section;

            return (
              <StepSectionWithContent
                {...{ title, ...sectionProps }}
                description={
                  description ? (
                    <RichTextRenderer content={description} />
                  ) : undefined
                }
              />
            );
          }
          case 'tabs_section': {
            const { items } = section;

            return (
              <TabsSection
                withSpacing
                items={items.map(({ title, ...item }) => ({
                  key: item.key,
                  heading: title,
                  content: (
                    <WalletsList
                      items={item.items.map((walletItem) => ({
                        title: walletItem.title,
                        key: walletItem.key,
                        description: walletItem.description ? (
                          <RichTextRenderer content={walletItem.description} />
                        ) : undefined,
                        src: walletItem.image?.url,
                        width: walletItem.image?.dimensions.width,
                        height: walletItem.image?.dimensions.height,
                        alt: walletItem.image?.alt,
                      }))}
                    />
                  ),
                }))}
                {...sectionProps}
              />
            );
          }
          case 'separator': {
            return <SeparatorSection {...sectionProps} />;
          }
          case 'step_section_with_media': {
            const { description, title, image } = section;

            return (
              <StepSectionWithMedia
                {...{ title, ...sectionProps }}
                src={image?.url}
                width={image?.dimensions?.width}
                height={image?.dimensions?.height}
                description={
                  description ? (
                    <RichTextRenderer content={description} />
                  ) : undefined
                }
              />
            );
          }
          case 'video_section': {
            const { title, videoUrl, description } = section;

            return (
              <VideoSection
                width={700}
                height={394}
                src={videoUrl}
                description={
                  description ? (
                    <RichTextRenderer content={description} />
                  ) : undefined
                }
                {...{ title }}
                {...sectionProps}
              />
            );
          }
          case 'text_with_media': {
            const { items, withBackground, isWide, spacing } = section;

            return (
              <TextMediaListSection
                items={items.map(({ description, image, ...item }) => ({
                  ...item,
                  src: image?.url,
                  width: image?.dimensions?.width,
                  height: image?.dimensions?.height,
                  description: description ? (
                    <RichTextRenderer content={description} />
                  ) : undefined,
                }))}
                topSpacing={
                  spacing
                    ? getEnumValueByStringValue(SectionSpacing, spacing)
                    : undefined
                }
                bottomSpacing={
                  spacing
                    ? getEnumValueByStringValue(SectionSpacing, spacing)
                    : undefined
                }
                {...{ ...sectionProps, isWide, withBackground }}
              />
            );
          }
          case 'flexepin_form_hero': {
            const {
              description,
              href,
              label,
              mode,
              modeType,
              size,
              title,
              maxAmount,
              minAmount,
              redirectUrlTemplate,
            } = section;

            return (
              <HeroWithForm
                description={
                  description ? (
                    <RichTextRenderer content={description} />
                  ) : undefined
                }
                linkButton={
                  href
                    ? {
                        href,
                        content: label || href,
                        mode: mode
                          ? getEnumValueByStringValue(ButtonMode, mode)
                          : undefined,
                        modeType: modeType
                          ? getEnumValueByStringValue(ButtonModeType, modeType)
                          : undefined,
                        size: size
                          ? getEnumValueByStringValue(ButtonSize, size)
                          : undefined,
                      }
                    : undefined
                }
                {...{ title }}
                {...sectionProps}
              >
                <BuyBitcoinForm
                  {...{
                    maxAmount,
                    minAmount,
                    redirectUrlTemplate,
                  }}
                />
              </HeroWithForm>
            );
          }
          case 'e-transfer_form': {
            const { items, minAmount } = section;

            return (
              <TransferFormSection
                paymentOptions={items}
                {...{ ...sectionProps, minAmount }}
              />
            );
          }
          case 'iframe': {
            const { url, title } = section;

            return <IframeWrapper {...{ ...sectionProps, title }} src={url} />;
          }
          case 'faq': {
            const { title, items } = section;

            return (
              <Faq
                {...{ ...sectionProps, title }}
                items={items.map(({ question, answer, key: faqItemKey }) => ({
                  heading: question,
                  content: answer ? (
                    <Box variant="cms">
                      <RichTextRenderer content={answer} />
                    </Box>
                  ) : undefined,
                  key: faqItemKey,
                }))}
              />
            );
          }
          case 'verify_transaction': {
            return <VerifyFormSection {...sectionProps} />;
          }
          case 'cms_content': {
            const { content } = section;

            return (
              <CmsSection
                {...sectionProps}
                content={
                  content ? <RichTextRenderer {...{ content }} /> : undefined
                }
              />
            );
          }
          case 'host_atm_form': {
            const { image, alignImageToRight, title } = section;

            return (
              <PersonalDetailsSection
                {...{
                  ...sectionProps,
                  title,
                  ...(image
                    ? {
                        src: image.url,
                        width: image.dimensions.width,
                        height: image.dimensions.height,
                      }
                    : {}),
                }}
                isReversed={!alignImageToRight}
              />
            );
          }
          case 'number_info_list': {
            const { items } = section;

            return (
              <NumberInfoListSection
                {...sectionProps}
                items={items.map(({ description, ...item }) => ({
                  ...item,
                  description: description ? (
                    <RichTextRenderer content={description} />
                  ) : undefined,
                }))}
              />
            );
          }
          case 'list_tabs': {
            const { items, title } = section;

            return (
              <NewsFeedSection
                {...sectionProps}
                title={title ? <RichTextRenderer content={title} /> : undefined}
                items={items.map((item) => ({
                  ...item,
                  items: item.items.map(({ content, ...innerItem }) => ({
                    ...innerItem,
                    content: content ? (
                      <RichTextRenderer {...{ content }} />
                    ) : undefined,
                  })),
                }))}
              />
            );
          }
          case 'tweets_list': {
            const { items } = section;

            return <TwitterFeedSection {...{ ...sectionProps, items }} />;
          }
          case 'navigation': {
            const { items } = section;

            return (
              <NavigationSection
                {...sectionProps}
                items={items.map(({ key: itemKey, title, url }) => ({
                  href: url,
                  key: itemKey,
                  label: title,
                }))}
              />
            );
          }
          case 'column_content': {
            const { items } = section;

            return (
              <ColumnsContentSection
                {...sectionProps}
                columns={items.map(({ key: itemKey, content, title }) => ({
                  key: itemKey,
                  content: content ? (
                    <RichTextRenderer {...{ content }} />
                  ) : undefined,
                  title,
                }))}
              />
            );
          }
          case 'products_list': {
            const { items, title } = section;

            return (
              <ProductListSection
                {...{ ...sectionProps, title }}
                items={items.map(
                  ({
                    key: itemKey,
                    description,
                    title: itemTitle,
                    url,
                    linkTitle,
                  }) => ({
                    description: description ? (
                      <RichTextRenderer content={description} />
                    ) : undefined,
                    href: url,
                    title: itemTitle,
                    key: itemKey,
                    linkTitle,
                  }),
                )}
              />
            );
          }
          case 'newsletter': {
            const { title, items } = section;

            return (
              <NewsletterSection
                {...{ ...sectionProps, title }}
                checkboxes={items.map(
                  ({ key: checkboxKey, agreementLabel }) => ({
                    key: checkboxKey,
                    label: agreementLabel ? (
                      <RichTextRenderer content={agreementLabel} />
                    ) : null,
                  }),
                )}
              />
            );
          }
          case 'featured_blog_posts': {
            const { title, description, posts, ctaLink, ctaText } = section;

            return (
              <ArticleTeaserSection
                {...{ ...sectionProps, title, description }}
                linkButton={
                  ctaLink && ctaText
                    ? {
                        href: ctaLink,
                        content: ctaText,
                      }
                    : undefined
                }
                items={posts.map(({ image, ...post }) => ({
                  ...post,
                  image: image
                    ? {
                        src: image.url,
                        alt: image.alt,
                        width: image.dimensions.width,
                        height: image.dimensions.height,
                      }
                    : undefined,
                }))}
              />
            );
          }
          default:
            return null;
        }
      };

      const sectionContent = getSectionContent();

      return sectionContent && sectionIndex !== 0 ? (
        <LazyHydrate
          {...{ key }}
          id={key}
          useDisplayContents={false}
          noWrapper="section"
          whenVisible={{
            rootMargin: '-100px',
          }}
          didHydrate={() => {
            console.log(`hydrate: ${section.type}, index: ${sectionIndex}`);
          }}
        >
          {sectionContent}
        </LazyHydrate>
      ) : (
        sectionContent
      );
    })}
  </Fragment>
);

export default PageSectionsRenderer;
