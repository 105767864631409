export enum SectionBackground {
  Mute = 'mute',
  Nude = 'nude',
  Gradient = 'gradient',
  MuteGradient = 'mute-gradient',
  Orange = 'orange',
  Light = 'light',
  Gray = 'gray',
  Image = 'image',
  HeroBanner = 'hero-banner',
  CtaBanner = 'cta-banner',
}

export enum SectionSpacing {
  Zero = 'zero',
  VerySmall = 'xs',
  Small = 's',
  Medium = 'm',
  Big = 'l',
  VeryBig = 'xl',
}
