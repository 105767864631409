import React, { Fragment } from 'react';
import { NextPage } from 'next';
import Head from 'next/head';

import HrefLangs from 'components/HrefLangs';
import PageSectionsRenderer from 'components/PageSectionsRenderer';
import Script from 'components/Script';
import { CatchAllInitialProps } from './types';

const CatchAll: NextPage<CatchAllInitialProps> = ({
  pageData: {
    title,
    description,
    seoTitle,
    seoDescription,
    pageSections,
    openGraphPictureUrl,
    code,
    alternateLanguages,
  },
}) => (
  <Fragment>
    <Head>
      <title>
        {title ||
          seoTitle ||
          'Bitcoin ATM - Buy and Sell Bitcoin with Cash - Low Fees | Localcoin'}
      </title>
      {seoDescription || description ? (
        <meta name="description" content={seoDescription || description} />
      ) : null}
      {openGraphPictureUrl ? (
        <meta name="og:image" content={openGraphPictureUrl} />
      ) : null}
    </Head>
    <HrefLangs {...{ alternateLanguages }} />
    <PageSectionsRenderer sections={pageSections} />
    {code && <Script>{code}</Script>}
  </Fragment>
);

//////////////////////
/////////////////////////////////////////////////////////
//////////

export default CatchAll;
