import { LinkButtonProps } from 'components/atoms/Buttons/Button/types';
import { SectionCommonProps } from 'components/styles/Section/types';

export enum CTAHorizontalMode {
  Default = 'default',
  Half = 'half',
}

export interface CTAPropsHorizontal {
  horizontalMode?: CTAHorizontalMode;
}

export interface CTAProps extends SectionCommonProps, CTAPropsHorizontal {
  title?: string;
  description?: ReactComponentReturnType;
  icon?: SvgComponentType;
  linkButton?: LinkButtonProps & { content?: ReactComponentReturnType };
}
