import { ensureTrailingSlash } from 'utils/lodash/client';

export const ensureCorrectHreflangTrailingSlash = (url: string): string => {
  try {
    const parsedUrl = new URL(url);

    return parsedUrl.pathname === '/' || !parsedUrl.pathname
      ? parsedUrl.origin
      : ensureTrailingSlash(url);
  } catch (_) {
    return ensureTrailingSlash(url);
  }
};
