const iconsContext = require.context(
  '../../../public/assets/svg',
  false,
  /\.svg$/,
);

export const getIconByName = (
  iconName: string,
): SvgComponentType | undefined => {
  const iconImportKey = iconsContext
    .keys()
    .find((key) => key.endsWith(`/${iconName}.svg`));

  if (!iconImportKey) {
    return undefined;
  }

  return iconsContext(iconImportKey).default;
};
