import React, { useEffect } from 'react';
import { ScriptProps } from './types';

const Script: React.FC<ScriptProps> = ({ children }) => {
  useEffect(() => {
    // eslint-disable-next-line no-eval
    eval(children);
  }, [children]);

  return null;
};

export default Script;
