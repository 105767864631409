import { LOCALES } from '../../../config/next/languages';

export const COUNTRY_TO_LOCALE_PREFIX_MAPPING: Partial<MappedObject<string>> = {
  Canada: '',
  'United States': LOCALES.us,
};

export const LOCALE_TO_LANG_MAPPING: Partial<MappedObject<string>> = {
  [LOCALES.ca]: 'en-CA',
  [LOCALES.fr]: 'fr-CA',
  [LOCALES.us]: 'en-US',
};

export enum CryptoCurrency {
  BTC = 'btc',
  ETH = 'eth',
  LTC = 'ltc',
}
